import { useNavigate } from 'react-router-dom'
import PropTypes from 'prop-types'
import classes from './AboutUsPreview.module.css'
import CTAButton from '../buttons/PrimaryButton'
import StarIcon from '../../assets/star.svg'
import FacebookIcon from '../Icon/Facebook'
import LinkedInIcon from '../Icon/LinkedIn'
import InstagramIcon from '../Icon/Instagram'
import XIcon from '../Icon/X'
import generateSrcSetAndSizes from '../../utils/generateSrcSetAndSizes'
import normalizeUrl from '../../utils/normalizeUrl'

const AboutPreview = ({ about }) => {
  const { name, aboutMe, image, contactNumber, email, facebook, instagram, linkedIn, twitter } = about || {}
  const navigate = useNavigate()

  const { srcSet, sizes } = generateSrcSetAndSizes(image)

  return (
    <>
      <section className={classes['about-me']}>
        <div className={classes['container']}>
          {/* header */}
          <div className={classes['about-header']}>
            <div className={`${classes['title-wrapper']} ${classes['row']}`}>
              <div className='highlight-text'>About</div>
              <h1>I am {name || 'Revenge Bhatta'}</h1>
            </div>
            <div className={`${classes['row']} ${classes['know-more']}`}>
              <CTAButton classes={classes['know-more-btn']} onClick={() => navigate('/about-me')}>
                Know More
              </CTAButton>
            </div>
          </div>
          {/* content wrapper */}
          <div className={classes['content-wrapper']}>
            {/* profile image */}
            <div className={classes['profile-image']}>
              <img src={image?.url} srcSet={srcSet} sizes={sizes} alt='profile image' />
            </div>
            {/* description wrapper */}
            <div className={classes['description-container']}>
              {/* about me description */}
              <div className={classes['description-wrapper']}>
                <div className={classes['about-content']}>
                  <p>{aboutMe}</p>
                </div>
                {/* contact information */}
                <div className={classes['contact-wrapper']}>
                  <div className={classes['contact-header']}>
                    {/* <object type='image/svg+xml' data={StarIcon} width='29.25' height='30' /> */}
                    <h3>Contact Information</h3>
                  </div>
                  <div className={classes['contact-info']}>
                    <div className={classes['top']}>
                      <div className={classes['email-wrapper']}>
                        <div className='small-text'>Email</div>
                        <a href={`mailto:${email}`}>{email}</a>
                      </div>
                      <div className={classes['phone-wrapper']}>
                        <div className='small-text'>Phone Number</div>
                        <a href={'tel:' + contactNumber}>{contactNumber}</a>
                      </div>
                    </div>
                    <div className={classes['bottom']}>
                      <div className={classes['social-media-wrapper']}>
                        <div className={classes['social-media']}>
                          <a href={instagram ? normalizeUrl(instagram) : '#'} target='_blank' rel='noreferrer'>
                            <InstagramIcon />
                          </a>
                          <a href={facebook ? normalizeUrl(facebook) : '#'} target='_blank' rel='noreferrer'>
                            <FacebookIcon />
                          </a>
                          <a href={linkedIn ? normalizeUrl(linkedIn) : '#'} target='_blank' rel='noreferrer'>
                            <LinkedInIcon />
                          </a>
                          <a href={twitter ? normalizeUrl(twitter) : '#'} target='_blank' rel='noreferrer'>
                            <XIcon />
                          </a>
                        </div>
                      </div>
                      <div className={classes['cta-wrapper']}>
                        <CTAButton onClick={() => navigate('/contact')}>Get In Touch</CTAButton>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default AboutPreview

AboutPreview.propTypes = {
  about: PropTypes.object
}
